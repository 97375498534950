import Vue from 'vue'
window.Vue = Vue
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import axios from 'axios'

import { extend } from 'vee-validate'
import { required, email, regex } from 'vee-validate/dist/rules'
import ContactForm from './components/ContactForm'
import CookieConsent from './components/CookieConsent'
import AppFixedHeader from './components/AppFixedHeader'
import HeroSlider from './components/HeroSlider'
import AboutSlider from './components/AboutSlider'
import AppNavBar from './components/AppNavBar'

Vue.component('ContactForm', ContactForm)
Vue.component('CookieConsent', CookieConsent)
Vue.component('AppFixedHeader', AppFixedHeader)
Vue.component('HeroSlider', HeroSlider)
Vue.component('AboutSlider', AboutSlider)
Vue.component('AppNavBar', AppNavBar)

// map
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import AppMap from './components/AppMap'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.component('l-map', LMap)
Vue.component('l-popup', LPopup)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('l-icon', LIcon)
Vue.component('AppMap', AppMap)



const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


extend('required', {
  ...required,
  message: 'The {_field_} is required'
})

extend('email', email)
extend('regex', regex)

extend('min', {
  validate (value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
})

extend('max', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field may not be greater than {length} characters'
})

extend('length', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field can have only {length} of checked items'
})

Vue.prototype.$axios = axios
Vue.config.productionTip = false
