<template>
  <div id="map" class="relative z-0 h-96 text-orange">
    <l-map :zoom="zoom" :center="center">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="position">
          <l-icon :icon-anchor="staticAnchor" :icon-size="[24, 24]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="w-6 h-6 block" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </l-icon>
          <l-popup>
            <div class="mb-4">
              <p>49 Queens Square</p>
              <p>Belfast BT1 3FG</p>
            </div>
              <a href="https://goo.gl/maps/ebX4texw273QSiLc6" target="_blank" rel="nofollow noreferrer noopener">
                Get Directions
              </a>
          </l-popup>
        </l-marker>
    </l-map>
  </div>
</template>
<script>
import { latLng } from 'leaflet'
export default {
  name: 'AppMap',
  data () {
    return {
       center: latLng(54.60092078355895, -5.9227134766723255),
       position: latLng(54.60092078355895, -5.9227134766723255),
       url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
       attribution: '',
       zoom: 13,
       subdomains: 'abcd',
       minZoom: 4,
       maxZoom: 19,
       staticAnchor: [12, 12]
     }
  }
}
</script>
