<template>
  <div v-swiper:HeroSlider="settings" style="overflow:inherit">
    <div class="swiper-wrapper text-center">
      <slot />
    </div>
    <div class="md:hidden slider-pagination swiper-pagination text-center"></div>
  </div>
</template>
<script>
export default {
  name: 'HeroSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        direction: "horizontal",
        loop: false,
        pagination: {
          clickable: true,
          el: '.slider-pagination'
        },
        spaceBetween: 20,
        breakpoints: {
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          }
        }
      }
    }
  }
}
</script>
