<template>
  <div v-swiper:AboutSlider="settings" style="overflow:inherit">
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AboutSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        direction: "horizontal",
        autoplay: { delay: 3000 },
        loop: true,
        loopAdditionalSlides: 3,
        spaceBetween: 50,
        breakpoints: {
          600: {
            slidesPerView: 2,
            spaceBetween: 20,
          }
        }
      }
    }
  }
}
</script>
